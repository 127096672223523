const CommentIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.97143 0H13.0286C14.8571 0 16 1.14286 16 2.97143V9.48571C16 11.3143 14.8571 12.4571 13.0286 12.4571H10.0571L4.45714 16V12.4571H2.97143C1.14286 12.4571 0 11.3143 0 9.48571V2.97143C0 1.14286 1.14286 0 2.97143 0Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default CommentIcon;
